import Nanotoast from "./NanoToast";

class DeepLinkButton {
  /**
   * This class needs to be added to the page where a deeplink button is added
   * It adds retro-action in the case the app doesn't load
   */
  constructor() {
    this.deeplinkButtons = $(".deeplink-div");

    this.deeplinkButtons.click((e) => {
      this.ShowToast(e.currentTarget);
    });
  }

  // Shows toast when deeplink button is clicked.
  ShowToast(target) {
    const successMessage = $(target).find(".deeplink-success-message").text();
    const successInfoButtonText = $(target)
      .find(".deeplink-success-button")
      .text();
    const successInfoUrl = $(target).find(".deeplink-success-url").text();

    this.successToast = new Nanotoast({
      parentContainer: "body",
      pos: "nanotoast-top-center",
      type: "info",
      text: successMessage,
      asideIcon: "fa fa-download",
      button: {
        text: successInfoButtonText,
        url: successInfoUrl,
        target: "_blank",
      },
    });
    this.successToast.Show();
  }
}

export default DeepLinkButton;
